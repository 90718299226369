<template>
  <div class="popup-header-container">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.popup-header-container {
  @apply flex flex-col items-center px-8 pt-8 text-xl text-gray-800  font-bold;
}
</style>
