<template>
  <Popup :open="open" @close="emits('close')">
    <PopupContainer>
      <PopupHeader>
        <SvgIcon :size="30" class="text-accent-500" name="circle-question"></SvgIcon>
        <div class="delete-confirmation-title">{{ $t('inbox.delete-message') }}</div>
        <div class="text-center text-gray-800 text-lg font-base font-regular w-full mt-5">
          {{ subtitle }}
        </div>
      </PopupHeader>
      <PopupBody>
        <Button
          block
          size="large"
          type="primary"
          color="danger"
          class="mb-5"
          @click="emits('delete')"
        >
          {{ $t('inbox.delete') }}
        </Button>
        <Button block size="large" type="secondary" @click="emits('close')">
          {{ $t('inbox.cancel') }}
        </Button>
      </PopupBody>
    </PopupContainer>
  </Popup>
</template>
<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import Popup from '@/components/common/popup/Popup.vue'
import PopupBody from '@/components/common/popup/PopupBody.vue'
import PopupContainer from '@/components/common/popup/PopupContainer.vue'
import PopupHeader from '@/components/common/popup/PopupHeader.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'

defineProps<{
  open: boolean
  subtitle?: string
}>()

const emits = defineEmits<{
  (e: 'close'): void
  (e: 'delete'): void
}>()
</script>

<style scoped lang="postcss">
.delete-confirmation-title {
  @apply text-lg pt-4 font-base w-full text-center text-gray-800 font-bold;
}
</style>
