import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import appointmentsRoutes from '@/modules/appointments/routes'
import AuthLayout from '@/modules/auth/layouts/AuthLayout.vue'
import { authMiddleware } from '@/modules/auth/middleware'
import authRoutes from '@/modules/auth/routes'
import { counterMiddleware } from '@/modules/counter/middleware'
import historyRoutes from '@/modules/history/routes'
import homeRoutes, { HOME } from '@/modules/home/routes'
import infoRoutes from '@/modules/info/routes'
import myProfileRoutes from '@/modules/my-profile/routes'
import newsRoutes from '@/modules/news/routes'
import messagingRoutes from '@/modules/patient-messaging/routes'
import settingsRoutes from '@/modules/settings/routes'
import surveyRoutes from '@/modules/survey/routes'

const routes: Array<RouteRecordRaw> = [
  ...authRoutes,
  ...appointmentsRoutes,
  ...settingsRoutes,
  ...myProfileRoutes,
  ...infoRoutes,
  ...newsRoutes,
  ...surveyRoutes,
  ...homeRoutes,
  ...historyRoutes,
  ...messagingRoutes,
  {
    path: '/',
    redirect: { name: HOME },
  },
  {
    path: '/500',
    component: () => import('@/views/500.vue'),
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/404.vue'),
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(authMiddleware)
router.afterEach(counterMiddleware)

export default router
