/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Logout } from '../models/Logout';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LogoutService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * An endpoint for logging out a user.
     * @returns Logout
     * @throws ApiError
     */
    public logoutCreate({
        requestBody,
    }: {
        requestBody: Logout,
    }): CancelablePromise<Logout> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/logout/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
