<template>
  <Dialog :open="!!message" @close="emits('close')">
    <DialogContainer>
      <DialogHeader :title="$t('inbox.message')" icon="chevron-left" @close="emits('close')" />
      <div class="message-details">
        <div class="message-details__title">{{ message?.title }}</div>
        <div class="message-details__item">
          <SvgIcon :size="20" name="person" class="mt-0.5" />
          <span class="message-details__item-label">{{ message?.practitioner_name }}</span>
        </div>
        <div class="message-details__item">
          <SvgIcon :size="20" name="clock" class="mt-0.5" />
          <span class="message-details__item-label">{{
            dayjs(message?.created_at).format('MMMM DD, HH:mm')
          }}</span>
        </div>
        <div class="message-details__item">
          <SvgIcon :size="20" name="text" class="mt-0.5" />
          <span class="message-details__item-label">{{ message?.body }}</span>
        </div>
      </div>
    </DialogContainer>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/common/dialog/Dialog.vue'
import DialogContainer from '@/components/common/dialog/DialogContainer.vue'
import DialogHeader from '@/components/common/dialog/DialogHeader.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import dayjs from '@/dayjs'
import type { PatientMessage } from '@/generated/api'

defineProps<{
  message: PatientMessage | null
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()
</script>

<style scoped lang="postcss">
.message-details {
  @apply p-5 flex flex-col gap-5;
  &__title {
    @apply text-accent-500 font-bold text-2xl;
  }
  &__item {
    @apply flex gap-5 pt-5 pl-3;
    &-label {
      @apply border-b border-gray-200 grow pb-5;
    }
  }
}
</style>
