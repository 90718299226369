import { Capacitor } from '@capacitor/core'
import { useLocalStorage } from '@vueuse/core'
import { merge } from 'lodash'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'
import { USER_ROLES } from '@/constants/constants'
import dayjs from '@/dayjs'
import type {
  CustomTokenRefresh,
  InvitationRetrieveToken,
  Login,
  LoginResponse,
  Logout,
  NullEnum,
  PasscodeNotificationChannelEnum,
  PatchedUser,
  ProfileReadOnly,
  ProfileTypeEnum,
  SendVerificationCode,
  UpdatePassword,
} from '@/generated/api'
import i18n from '@/i18n'

import { useCounterStore } from '../counter/store'

type TokenInfo = {
  access?: string
  refresh?: string
  timestamp?: number
  profile_type?: ProfileTypeEnum
}

type TokenResponse = LoginResponse | CustomTokenRefresh

const refreshInterval = 1000 * 60 * 4
const maxLifeTime = 1000 * 60 * 2

export const useAuthStore = defineStore('auth', () => {
  const refreshTokenLifetime = useLocalStorage('refresh_lifetime', 1000 * 60 * 15)
  const fcmToken = useLocalStorage('fcm_token', '', { mergeDefaults: true })
  const apiClient = useApiClient()
  const user = ref(null as ProfileReadOnly | null)
  const onboardingUserInfo = ref(null as InvitationRetrieveToken | null)
  const token = Capacitor.isNativePlatform()
    ? ref({} as TokenInfo)
    : useLocalStorage('clienia_auth_token', {} as TokenInfo, { mergeDefaults: true })
  const deviceToken = useLocalStorage('device_token', '', { mergeDefaults: true }) // for native only because secure cookies doesn't work in iOS
  const counterStore = useCounterStore()
  if (Capacitor.isNativePlatform()) {
    token.value = JSON.parse(localStorage.getItem('longLivedToken') ?? '{}')
  }

  let refreshTimeout = null as null | ReturnType<typeof setTimeout>
  setupRefresh()

  async function fetchOnboardingUserInfoBeforeLogin(
    token: string
  ): Promise<InvitationRetrieveToken | null> {
    if (onboardingUserInfo.value) {
      return onboardingUserInfo.value
    }
    onboardingUserInfo.value = await apiClient.invitation.invitationRetrieve({ token })

    i18n.global.locale.value =
      user.value?.user.app_language ?? onboardingUserInfo.value.language ?? i18n.global.locale.value
    dayjs.locale(i18n.global.locale.value)
    localStorage.setItem('lang', i18n.global.locale.value)

    return onboardingUserInfo.value
  }

  async function fetchMe(force = false): Promise<ProfileReadOnly | null> {
    if (user.value && !force) {
      return user.value
    }
    if (!token.value.refresh) {
      return null
    }
    user.value = await apiClient.me.meRetrieve()
    token.value.profile_type = user.value?.type
    if (user.value?.type === USER_ROLES.PATIENT) {
      refreshTokenLifetime.value = 1000 * 60 * 60 * 4
    }

    // by default, we should use user's language that we get from INES
    i18n.global.locale.value = user.value?.user.app_language || i18n.global.locale.value
    dayjs.locale(i18n.global.locale.value)
    localStorage.setItem('lang', i18n.global.locale.value)

    return user.value
  }

  async function updateMe(options: PatchedUser): Promise<void> {
    const baseUser = await apiClient.me.meUpdatePartialUpdate({
      requestBody: options,
    })
    user.value = merge(user.value, { user: baseUser })
  }

  async function updateMePassword(passwords: UpdatePassword): Promise<void> {
    await apiClient.me.meUpdatePasswordUpdate({
      requestBody: passwords,
    })
  }

  async function sendPushNotificationsToken(token: string) {
    fcmToken.value = token
    await apiClient.fcmtoken.fcmtokenCreate({ requestBody: { token } })
  }

  async function removePushNotificationsToken(token: string) {
    await apiClient.fcmtoken.fcmtokenDestroy({ token })
  }

  async function checkCredentialsForCode(
    email: string,
    password: string,
    phone?: string,
    channel?: PasscodeNotificationChannelEnum | '' | null | NullEnum
  ) {
    const requestBody = { email, password } as SendVerificationCode

    if (Capacitor.isNativePlatform()) {
      requestBody.device_token = deviceToken.value
    }
    if (phone) {
      requestBody.default_phone_number = phone
    }
    if (channel) {
      requestBody.passcode_notification_channel = channel
    }
    return apiClient.fa.faSendVerificationCodeCreate({
      requestBody,
    })
  }

  async function login(email: string, password: string, code?: string) {
    // to be sure that token access is valid and not expired let's logout before login
    logout()
    let requestBody: Login = { email, password }
    if (code) {
      requestBody = { ...requestBody, code }
    }
    if (Capacitor.isNativePlatform()) {
      requestBody.device_token = deviceToken.value
    }
    const result = await apiClient.login.loginCreate({
      requestBody,
      isNewVersion: true,
    })
    if (Capacitor.isNativePlatform()) {
      deviceToken.value = result.device_token
    }
    const access = result.access
    const refresh = result.refresh
    if (access && refresh) {
      token.value = {
        access,
        refresh,
        timestamp: Date.now(),
      }
      setupRefresh()
    }
    const user = await fetchMe()
    if (user?.type === USER_ROLES.PATIENT) {
      localStorage.removeItem('practitionerData')
    }
    if (Capacitor.isNativePlatform() && fcmToken.value) {
      await sendPushNotificationsToken(fcmToken.value)
    }
    return user
  }

  function stopRefreshTimeout() {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout)
    }
  }

  async function logout(force?: boolean) {
    if (token.value.refresh && force) {
      const requestBody: Logout = { refresh: token.value.refresh }

      if (Capacitor.isNativePlatform()) {
        requestBody.device_token = deviceToken.value
      }
      if (fcmToken.value) {
        requestBody.fcm_token = fcmToken.value
      }
      await apiClient.logout.logoutCreate({ requestBody }).catch((e) => {
        console.log(e)
      }) // TODO remove after review
    }
    token.value = {}
    stopRefreshTimeout()

    user.value = null
    onboardingUserInfo.value = null
    counterStore.counter = null
  }

  async function removeFcmToken() {
    if (Capacitor.isNativePlatform() && fcmToken.value) {
      await removePushNotificationsToken(fcmToken.value)
    }
    fcmToken.value = ''
  }

  function storeToken(access: string | null, refresh: string | null, long?: boolean) {
    if (access && refresh) {
      token.value = {
        access,
        refresh,
        timestamp: Date.now(),
      }
      if (long) {
        localStorage.setItem('longLivedToken', JSON.stringify(token.value))
      }
      setupRefresh(long)
    }
  }

  async function refreshToken(
    currentRefreshToken: string = token.value.refresh || ''
  ): Promise<TokenResponse | void> {
    stopRefreshTimeout()

    localStorage.removeItem('longLivedToken')

    if (!token.value.refresh) return

    const result = (await apiClient.login.loginRefreshCreate({
      requestBody: {
        refresh: currentRefreshToken,
        access: '',
        profile_type: token.value.profile_type,
      },
    })) as TokenResponse

    storeToken(result.access, result.refresh)
    return result
  }

  async function refreshLongLivedToken(
    currentRefreshToken: string = token.value.refresh || ''
  ): Promise<TokenResponse | void> {
    stopRefreshTimeout()

    const result = (await apiClient.login.loginRefreshLongLivedCreate({
      requestBody: { refresh: currentRefreshToken, access: '' },
    })) as TokenResponse

    storeToken(result.access, result.refresh, true)
    return result
  }

  function setupRefresh(long?: boolean) {
    const { timestamp, refresh } = token.value
    if (timestamp && refresh) {
      const now = Date.now()
      const timePassed = now - timestamp
      const timeLeft = refreshInterval - timePassed
      if (timePassed < maxLifeTime) {
        refreshTimeout = setTimeout(async () => {
          if (long) {
            await refreshLongLivedToken(refresh)
          } else {
            await refreshToken(refresh)
          }
        }, timeLeft)
      }
    }
  }

  async function getAccessToken(): Promise<string | undefined> {
    const { access, refresh, timestamp } = token.value
    if (!access || !refresh || !timestamp) {
      return undefined
    }
    const now = Date.now()
    const timePassed = now - timestamp
    if (timePassed < refreshInterval) {
      return access
    }

    const result = await refreshToken(refresh)
    return result?.access
  }

  async function setNewPassword(password: string, token: string) {
    await apiClient.setPassword.setPasswordCreate({
      requestBody: { token, password },
    })
  }

  async function resetPassword(email: string) {
    await apiClient.resetPassword.resetPasswordCreate({
      requestBody: { email },
    })
  }

  function getToken() {
    return token.value
  }

  return {
    token,
    refreshTokenLifetime,
    user,
    onboardingUserInfo,
    fetchOnboardingUserInfoBeforeLogin,
    fetchMe,
    updateMe,
    updateMePassword,
    login,
    logout,
    getAccessToken,
    setNewPassword,
    resetPassword,
    sendPushNotificationsToken,
    removePushNotificationsToken,
    removeFcmToken,
    refreshToken,
    stopRefreshTimeout,
    setupRefresh,
    refreshLongLivedToken,
    getToken,
    checkCredentialsForCode,
  }
})
