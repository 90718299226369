import { defineStore } from 'pinia'

import { useApiClient } from '@/api'
import type { PatchedPatientMessage } from '@/generated/api'

export const useInboxStore = defineStore('inbox', () => {
  const apiClient = useApiClient()

  const getInboxMessages = async (search: string) => {
    return apiClient.patients.patientsMessagesList({
      search,
      limit: 20,
    })
  }

  const getInboxMessage = async (id: number) => {
    return apiClient.patients.patientsMessagesRetrieve({ id })
  }

  const getNextPage = (url: string): Promise<any> => {
    const urlObj = new URL(url)
    const newURLWithoutHost = urlObj.href.replace(urlObj.origin, '')
    return apiClient.request.request({ method: 'GET', url: newURLWithoutHost })
  }

  const deleteInboxMessage = async (id: number) => {
    return apiClient.patients.patientsMessagesDestroy({ id })
  }

  const updateInboxMessage = async (id: number, requestBody: PatchedPatientMessage) => {
    return apiClient.patients.patientsMessagesPartialUpdate({ id, requestBody })
  }

  return { getInboxMessages, getNextPage, deleteInboxMessage, updateInboxMessage, getInboxMessage }
})
