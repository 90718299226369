import type { RouteRecordRaw } from 'vue-router'

export const MESSAGING = 'patient-messaging'

export default [
  {
    path: '/patient-messaging',
    name: MESSAGING,
    component: () => import('./views/PatientMessaging.vue'),
  },
] as RouteRecordRaw[]
