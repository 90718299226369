<template>
  <div
    class="toast-message"
    :class="containerClass"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-message-content">
      <Notification
        :severity="message.severity"
        :summary="message.summary"
        :detail="message.detail"
        :icon="message.icon"
      />
      <div v-if="message.closable !== false" class="close-button" @click="close">
        <div class="close-content">
          <div v-if="message.closeText" class="close-text">{{ message.closeText }}</div>
          <SvgIcon v-else name="crossmark" :size="closeIconSize" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, ref } from 'vue'

import Notification from '@/components/common/form/Notification.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import type { Message } from '@/components/common/toast/Message'

const closeTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

const props = defineProps({
  message: { type: Object as PropType<Message>, required: true },
  closeIconSize: { type: Number, default: 20 },
})

const emit = defineEmits<{
  (e: 'close', message: Message): void
}>()

const containerClass = computed(() => `toast-message-${props.message.severity}`)

const clearCloseTimeout = () => {
  if (closeTimeout.value) {
    clearTimeout(closeTimeout.value)
    closeTimeout.value = null
  }
}
const close = () => {
  clearCloseTimeout()
  emit('close', props.message)
}

onMounted(() => {
  if (props.message.ttl) {
    closeTimeout.value = setTimeout(close, props.message.ttl)
  }
})

onBeforeUnmount(clearCloseTimeout)
</script>

<style scoped lang="postcss">
.toast-message {
  box-shadow: 0px 16px 32px rgba(34, 38, 46, 0.2);
  border-radius: 10px;
  @apply bg-gray-900 text-base text-white mb-1 w-[calc(100vw-40px)] sm:w-auto;
}

.toast-message.toast-message-info {
  @apply bg-accent-900;
}

.toast-message :deep(.notification) {
  @apply flex-1 bg-transparent pr-5 sm:min-w-[24rem] sm:max-w-[24rem];

  .notification-icon {
    @apply text-gray-50;
  }
}

.toast-message :deep(.notification.info .notification-icon) {
  @apply text-accent-400;
}

.toast-message.toast-message-success {
  @apply bg-key-900;
}

.toast-message :deep(.notification.success .notification-icon) {
  @apply text-key-400;
}

.toast-message.toast-message-warn {
  @apply bg-warning-900;
}

.toast-message :deep(.notification.warn .notification-icon) {
  @apply text-warning-400;
}

.toast-message.toast-message-error {
  @apply bg-danger-900;
}

.toast-message :deep(.notification.error .notification-icon) {
  @apply text-danger-400;
}

.toast-message-content {
  @apply flex items-start;
}

.close-button {
  @apply cursor-pointer self-stretch flex border-l border-white-20 transition-colors;
}

.close-content {
  @apply p-3 self-stretch flex flex-col justify-center text-white-60 hover:text-white hover:bg-white-20 transition-colors;
}

.close-text {
  @apply px-2 whitespace-nowrap font-bold;
}
</style>
