<template>
  <div class="status" :class="{ [color]: true, [size]: true }">
    <SvgIcon v-if="icon" :size="iconSize" :name="icon" />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'

import SvgIcon from '@/components/common/SvgIcon.vue'

const props = defineProps({
  color: {
    type: String as PropType<'key' | 'accent' | 'danger' | 'warning' | 'white' | 'gray'>,
    default: 'white',
  },
  size: { type: String as PropType<'xs' | 'small' | 'medium' | 'large'>, default: 'medium' },
  icon: { type: String },
})

const iconSize = computed(() => (props.size === 'large' ? 16 : 12))
</script>

<style scoped lang="postcss">
.status {
  @apply text-sm inline-flex rounded-lg font-bold items-center bg-gray-500 text-white whitespace-nowrap;

  .svg-icon {
    @apply mr-[2px] ml-[-2px] flex-shrink-0;
  }

  &.key {
    @apply bg-key-500;
  }

  &.info {
    @apply bg-accent-500;
  }

  &.danger {
    @apply bg-danger-500;
  }

  &.accent {
    @apply bg-accent-500;
  }

  &.warning {
    @apply bg-warning-500;
  }

  &.gray {
    @apply bg-gray-500;
  }

  &.white {
    @apply bg-white text-accent-500;
  }
  &.xs {
    @apply text-base px-[8px] py-0 h-5;
  }

  &.small {
    @apply text-sm px-[6px] py-[2px];
  }

  &.medium {
    @apply text-base px-2;
  }

  &.large {
    @apply text-lg px-[10px];
  }
}
</style>
