import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'
import { SURVEY_STATUSES } from '@/constants/constants'
import type {
  BSCLQuestions,
  CurrentPositionEnum,
  PaginatedPatientListReadOnlyList,
  PaginatedReadOnlyShortBSCLSurveyList,
  PatchedUpdateOnlySurvey,
  PractitionerRequestSurvey,
  Status87aEnum,
  UpdateOnlyBSCLSurvey,
} from '@/generated/api'

export const useSurveyStore = defineStore('survey', () => {
  const practitionerData: any = useLocalStorage('practitionerData', {}, { mergeDefaults: true })

  const apiClient = useApiClient()

  const bsclList = ref(null as PaginatedReadOnlyShortBSCLSurveyList | null)
  const bsclQuestions = ref(null as BSCLQuestions[] | null)
  const selectedBSCL = ref(null as UpdateOnlyBSCLSurvey | null)
  const currentPosition = ref(1 as CurrentPositionEnum | undefined)

  function getAvailableSurveys(episodeOfCare: number[]) {
    return apiClient.surveys.surveysList({ limit: 10000, episodeOfCare })
  }

  function getActiveSurveys(episodeOfCare: number[]) {
    return apiClient.surveys.surveysList({
      limit: 10000,
      episodeOfCare,
      status: [SURVEY_STATUSES.REQUESTED, SURVEY_STATUSES.IN_PROGRESS],
    })
  }

  function getSurveyDetails(id: number) {
    return apiClient.surveys.surveysRetrieve({ id })
  }

  function updateSurveyResults(id: number, requestBody: PatchedUpdateOnlySurvey) {
    return apiClient.surveys.surveysPartialUpdate({ id, requestBody })
  }

  async function getBSCLList(
    options: {
      ordering?: string
      startGte?: string
      startLte?: string
      limit?: number
      episodeOfCare?: number[]
      status?: Status87aEnum[]
    } = {}
  ): Promise<PaginatedReadOnlyShortBSCLSurveyList | null> {
    bsclList.value = await apiClient.bscl.bsclList({
      limit: 10000,
      ...options,
    })
    return bsclList.value
  }

  async function getBSCLDetails(id: number): Promise<UpdateOnlyBSCLSurvey> {
    selectedBSCL.value = (await apiClient.bscl.bsclRetrieve({ id })) as UpdateOnlyBSCLSurvey
    currentPosition.value = selectedBSCL.value?.current_position
    return selectedBSCL.value
  }

  async function getBSCLQuestions(): Promise<BSCLQuestions[]> {
    bsclQuestions.value = await apiClient.bscl.bsclQuestionsList()
    return bsclQuestions.value
  }

  async function updateBSCL({
    id,
    bscl,
  }: {
    id: number
    bscl: Partial<UpdateOnlyBSCLSurvey>
  }): Promise<UpdateOnlyBSCLSurvey | null> {
    selectedBSCL.value = await apiClient.bscl.bsclPartialUpdate({
      id,
      requestBody: bscl,
    })
    currentPosition.value = selectedBSCL.value?.current_position || 1
    return selectedBSCL.value
  }

  function getPatientList(
    search = '',
    payload?: { limit?: number; offset?: number; ordering?: string }
  ): Promise<PaginatedPatientListReadOnlyList> {
    return apiClient.patients.patientsList({ search, ...payload })
  }

  function getNextPage(url: string): Promise<any> {
    const urlObj = new URL(url)
    const newURLWithoutHost = urlObj.href.replace(urlObj.origin, '')
    return apiClient.request.request({ method: 'GET', url: newURLWithoutHost })
  }

  function getPatientsEpisodesOfCareList(patientId: number) {
    return apiClient.patients.patientsEpisodesOfCareList({ patientId })
  }

  function getEpisodeTemplates({ eocId, patientId }: { eocId: number; patientId: number }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesList({ eocId, patientId })
  }

  function getSurveyHistoryList({
    patientId,
    eocId,
    templateId,
  }: {
    patientId: number
    eocId: number
    templateId: number
  }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesHistoryList({
      patientId,
      eocId,
      templateId,
      limit: 15,
    })
  }

  function getSurveyHistoryDetails({
    patientId,
    eocId,
    templateId,
    surveyId,
  }: {
    patientId: number
    templateId: number
    eocId: number
    surveyId: number
  }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesHistoryRetrieve({
      patientId,
      templateId,
      eocId,
      surveyId,
    })
  }

  function getActiveSurvey({
    patientId,
    templateId,
    eocId,
  }: {
    patientId: number
    templateId: number
    eocId: number
  }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesActiveSurveyRetrieve({
      patientId,
      eocId,
      templateId,
    })
  }

  function startPractitionerSurvey(
    { patientId, templateId, eocId }: { patientId: number; templateId: number; eocId: number },
    shared: boolean
  ) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesRequestCreate({
      patientId,
      eocId,
      templateId,
      requestBody: { shared } as unknown as PractitionerRequestSurvey,
    })
    //TODO: https://github.com/ferdikoomen/openapi-typescript-codegen/issues/432
  }

  function updatePractitionerSurvey({
    patientId,
    templateId,
    eocId,
    requestBody,
  }: {
    patientId: number
    templateId: number
    eocId: number
    surveyId: number
    requestBody: PatchedUpdateOnlySurvey
  }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesRespondPartialUpdate({
      patientId,
      eocId,
      templateId,
      requestBody,
    })
  }

  function cancelPractitionerSurvey({
    patientId,
    templateId,
    eocId,
  }: {
    patientId: number
    templateId: number
    eocId: number
  }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesCancelPartialUpdate({
      patientId,
      eocId,
      templateId,
    })
  }

  function getEpisodesOfCareList() {
    return apiClient.episodesOfCare.episodesOfCareList({})
  }

  function getPatientLastBSCL({ patientId, eocId }: { patientId: number; eocId: number }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesBsclRetrieve({
      patientId,
      eocId,
    })
  }

  function getBSCLHistoryList({ patientId, eocId }: { patientId: number; eocId: number }) {
    return apiClient.patients.patientsEpisodesOfCareSurveyTemplatesBsclHistoryList({
      patientId,
      eocId,
    })
  }

  async function getBSCLHistoryDetails({
    patientId,
    eocId,
    surveyId,
  }: {
    patientId: number
    eocId: number
    surveyId: number
  }) {
    selectedBSCL.value =
      (await apiClient.patients.patientsEpisodesOfCareSurveyTemplatesBsclHistoryRetrieve({
        patientId,
        eocId,
        surveyId,
      })) as UpdateOnlyBSCLSurvey
    currentPosition.value = selectedBSCL.value?.current_position ?? 1
    return selectedBSCL.value
  }

  async function getActiveBSCL({ patientId, eocId }: { patientId: number; eocId: number }) {
    selectedBSCL.value =
      (await apiClient.patients.patientsEpisodesOfCareSurveyTemplatesBsclActiveSurveyRetrieve({
        patientId,
        eocId,
      })) as UpdateOnlyBSCLSurvey

    currentPosition.value = selectedBSCL.value?.current_position ?? 1
    return selectedBSCL.value
  }

  async function updatePractitionerBSCL({
    eocId,
    patientId,
    requestBody,
  }: {
    patientId: number
    eocId: number
    requestBody: Partial<UpdateOnlyBSCLSurvey>
  }) {
    selectedBSCL.value =
      await apiClient.patients.patientsEpisodesOfCareSurveyTemplatesBsclRespondPartialUpdate({
        eocId,
        patientId,
        requestBody,
      })

    currentPosition.value = selectedBSCL.value?.current_position ?? 1
    return selectedBSCL.value
  }

  return {
    getBSCLDetails,
    updatePractitionerBSCL,
    getBSCLHistoryDetails,
    getActiveBSCL,
    getBSCLHistoryList,
    getPatientLastBSCL,
    getBSCLList,
    bsclQuestions,
    updateBSCL,
    getBSCLQuestions,
    selectedBSCL,
    currentPosition,
    getPatientList,
    getNextPage,
    getPatientsEpisodesOfCareList,
    getEpisodeTemplates,
    getSurveyHistoryDetails,
    getActiveSurvey,
    getSurveyHistoryList,
    startPractitionerSurvey,
    updatePractitionerSurvey,
    cancelPractitionerSurvey,
    getEpisodesOfCareList,
    getAvailableSurveys,
    getSurveyDetails,
    updateSurveyResults,
    getActiveSurveys,
    practitionerData,
  }
})
