<template>
  <Teleport to="body">
    <Transitions name="fade">
      <Overlay v-if="open" class="overlay" @click="emit('close')">
        <Button
          v-if="closeButton"
          type="secondary"
          size="medium"
          color="gray"
          class="close-button"
          icon="crossmark"
          @click="emit('close')"
        />
      </Overlay>
    </Transitions>
    <transition name="popup">
      <div
        v-if="open"
        class="popup"
        :class="{ 'with-close-button': closeButton }"
        v-bind="$attrs"
        @click="emit('close')"
      >
        <slot />
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, watchEffect } from 'vue'

import Button from '@/components/common/buttons/Button.vue'
import Overlay from '@/components/common/overlay/Overlay.vue'
import Transitions from '@/components/common/transition/Transitions.vue'

const props = defineProps({
  open: { type: Boolean, default: false },
  closeButton: { type: Boolean, default: false },
})

const emit = defineEmits<{
  (e: 'opened'): void
  (e: 'close'): void
}>()

watchEffect(() => {
  if (props.open) {
    emit('opened')
  }
})

const keyboardHandler = (event: KeyboardEvent) => {
  if (props.open && event.code?.toLowerCase() === 'escape') {
    emit('close')
  }
}

onMounted(() => {
  window.addEventListener('keydown', keyboardHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', keyboardHandler)
})
</script>

<style scoped lang="postcss">
.popup {
  @apply absolute inset-0 z-popup flex flex-col items-center justify-center pointer-events-none px-5;

  &.with-close-button {
    @apply p-2 sm:p-10 lg:px-32;
  }

  > div {
    @apply pointer-events-auto;
  }
}

.overlay {
  @apply z-popup-overlay;
}

.close-button {
  @apply absolute right-10 top-10 cursor-pointer;
}

/* Transition does not work properly if removed from here to <Transitions> component */
.popup-enter-active,
.popup-leave-active {
  @apply transition-[top,opacity];
}

.popup-enter-from,
.popup-leave-to {
  @apply top-[100vh] opacity-0;
}
</style>
