<template>
  <component
    :is="tag"
    class="button"
    :class="{
      [size]: true,
      [type]: true,
      [color]: true,
      'icon-left': (icon || loading) && !iconRight,
      'icon-right': (icon || loading) && iconRight,
      'with-chevron': chevron,
      'icon-only': iconOnlyDetected,
      'block-button': block,
    }"
    :disabled="disabled"
    :type="buttonType"
    v-bind="$attrs"
  >
    <SvgIcon v-if="!loading && icon && !iconRight" class="icon" :name="icon" />
    <div v-if="loading && !iconRight" class="icon loading-icon">
      <Loader class="loader" />
    </div>
    <span class="label">
      <slot v-if="!iconOnlyDetected" />
    </span>
    <div v-if="loading && iconRight" class="icon loading-icon">
      <Loader class="loader" />
    </div>
    <SvgIcon v-if="!loading && icon && iconRight" class="icon" :name="icon" />
    <SvgIcon v-if="chevron" class="chevron" :name="chevron" />
  </component>
</template>

<script setup lang="ts">
import type { PropType, SetupContext } from 'vue'
import { computed, useSlots } from 'vue'

import Loader from '@/components/common/loader/Loader.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'

const slots: SetupContext['slots'] = useSlots()

const props = defineProps({
  size: { type: String as PropType<'small' | 'medium' | 'large'>, default: 'medium' },
  type: { type: String as PropType<'primary' | 'secondary' | 'text'>, default: 'primary' },
  color: {
    type: String as PropType<'key' | 'gray' | 'danger' | 'accent'>,
    default: 'key',
  },
  tag: { type: String, default: 'button' },
  icon: { type: String },
  chevron: { type: String },
  loading: { type: Boolean, default: false },
  iconRight: { type: Boolean, default: false },
  iconOnly: { type: Boolean, default: false },
  block: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  buttonType: { type: String as PropType<'button' | 'submit' | 'reset'>, default: 'button' },
  textSize: { type: String, default: '14px' },
})

const iconOnlyDetected = computed(() => props.iconOnly || !slots.default)
</script>

<style scoped lang="postcss">
.button {
  @apply inline-flex items-center justify-center align-middle rounded-full border-none
  text-base font-bold border transition-all select-none whitespace-nowrap;

  .loader {
    @apply w-full h-full text-white;
  }

  &.block-button {
    @apply flex w-full;
  }

  .icon {
    @apply flex-shrink-0;
  }

  &:not(.icon-only) {
    .label {
      @apply flex-grow;
    }
  }

  /* extra CSS selectors specificity needed to reset shadows in one place */
  &.small.primary[disabled],
  &.medium.primary[disabled],
  &.large.primary[disabled],
  &.small.secondary[disabled],
  &.medium.secondary[disabled],
  &.large.secondary[disabled] {
    @apply cursor-not-allowed shadow-none hover:shadow-none active:shadow-none;
  }

  /* Fixes the ink outside of rounded borders issue in safari, see https://stackoverflow.com/a/23735769/2848264 */
  transform: translateZ(0);

  /* optimize browser work on hover */
  transition-property: box-shadow, background-color, border-color, color, transform;

  &.small {
    @apply text-sm rounded;

    padding: 4px 12px;

    &.primary {
      @apply shadow-small-dark hover:shadow-small-dark-hover active:shadow-small-dark;
    }

    &.secondary {
      @apply shadow-small-light hover:shadow-small-light-hover active:shadow-small-light;
    }

    .icon {
      @apply w-4 h-4;
    }

    .label {
      @apply text-sm overflow-ellipsis font-bold;
    }
  }

  &.medium {
    padding: 8px 20px;
    @apply rounded-[18px];

    &.primary {
      @apply shadow-medium-dark hover:shadow-medium-dark-hover active:shadow-medium-dark;
    }

    &.secondary {
      @apply shadow-medium-light hover:shadow-medium-light-hover active:shadow-medium-light;
    }

    .icon {
      @apply block w-5 h-5;
    }

    .label {
      @apply text-base overflow-ellipsis font-bold;
    }
  }

  &.large {
    @apply text-lg rounded-[24px];

    padding: 12px 24px;

    &.primary {
      @apply shadow-medium-dark hover:shadow-medium-dark-hover active:shadow-medium-dark;
    }

    &.secondary {
      @apply shadow-medium-light hover:shadow-medium-light-hover active:shadow-medium-light;
    }

    .icon {
      @apply block w-6 h-6;
    }

    .label {
      @apply text-lg-button overflow-ellipsis font-bold;
    }
  }

  &.primary {
    @apply text-white;

    &[disabled] {
      @apply text-white-60;
    }

    .loader {
      @apply text-white;
    }
  }

  &.secondary {
    @apply bg-white
    hover:bg-gray-50
    active:bg-gray-75;

    &[disabled] {
      @apply bg-gray-75 hover:bg-gray-75;
    }
  }

  &.text {
    @apply border-transparent bg-transparent shadow-none hover:shadow-none hover:bg-gray-50 active:bg-gray-75;

    &[disabled] {
      @apply border-transparent bg-transparent hover:border-transparent  hover:bg-transparent;
    }
  }

  &.key {
    &.primary {
      @apply bg-key-500
    hover:bg-key-600
       active:bg-key-700;

      &[disabled] {
        @apply bg-key-600 hover:bg-key-600  text-white-60;
      }
    }

    &.secondary,
    &.text {
      @apply text-key-500 hover:text-key-600 active:text-key-700;

      &[disabled] {
        @apply text-key-500-60 hover:text-key-500-60;
      }

      .loader {
        @apply text-key-500 hover:text-key-600 active:text-key-700;
      }
    }
  }

  &.accent {
    &.primary {
      @apply bg-accent-500
      hover:bg-accent-600
      active:bg-accent-700;

      &[disabled] {
        @apply bg-accent-600 hover:bg-accent-600  text-white-60;
      }
    }

    &.secondary,
    &.text {
      @apply text-accent-500 hover:text-accent-600 active:text-accent-700;

      &[disabled] {
        @apply text-accent-500-60 hover:text-accent-500-60;
      }

      .loader {
        @apply text-accent-500 hover:text-accent-600 active:text-accent-700;
      }
    }
  }

  &.danger {
    &.primary {
      @apply bg-danger-500
       hover:bg-danger-600
       active:bg-danger-700;

      &[disabled] {
        @apply bg-danger-600 hover:bg-danger-600 hover:text-white-60;
      }
    }

    &.secondary,
    &.text {
      @apply text-danger-500 hover:text-danger-600 active:text-danger-700;

      &[disabled] {
        @apply text-danger-500-60 hover:text-danger-500-60;
      }

      .loader {
        @apply text-danger-500 hover:text-danger-600 active:text-danger-700;
      }
    }
  }

  &.small.icon-left {
    @apply px-2;

    .icon {
      @apply mr-2;
    }

    &.block-button {
      @apply px-2;

      .label {
        @apply mr-4 pr-2;
      }
    }
  }

  &.small.icon-right {
    @apply pr-2;

    .icon {
      @apply ml-2;
    }

    &.block-button {
      @apply px-2;

      .label {
        @apply ml-4 pl-2;
      }
    }
  }

  &.small.with-chevron {
    @apply pr-2;

    .chevron {
      @apply m-0 w-[10px] h-[10px] ml-2;
    }
  }

  &.medium.icon-left {
    @apply pl-3;

    .icon {
      @apply mr-3;
    }

    &.block-button {
      @apply px-4;

      .label {
        @apply mr-5 pr-4;
      }
    }
  }

  &.medium.icon-right {
    @apply pr-3;

    .icon {
      @apply ml-3;
    }

    &.block-button {
      @apply px-4;

      .label {
        @apply ml-5 pl-4;
      }
    }
  }

  &.medium.with-chevron {
    @apply pr-3;

    .chevron {
      @apply m-0 w-3 h-3 ml-3;
    }
  }

  &.large.icon-left {
    @apply pl-4;

    .icon {
      @apply mr-4;
    }

    &.block-button {
      @apply px-5;

      .label {
        @apply mr-6 pr-5;
      }
    }
  }

  &.large.icon-right {
    @apply pr-4;

    .icon {
      @apply ml-4;
    }

    &.block-button {
      @apply px-5;

      .label {
        @apply ml-6 pl-5;
      }
    }
  }

  &.large.with-chevron {
    @apply pr-4;

    .chevron {
      @apply m-0 w-4 h-4 ml-4;
    }
  }

  &.icon-only {
    @apply rounded-full flex-shrink-0;

    &.icon-left .icon,
    &.icon-right .icon {
      @apply m-0;
    }

    &.button {
      @apply w-9 h-9 p-0;

      &.small {
        @apply w-6 h-6;
      }

      &.large {
        @apply w-12 h-12;
      }
    }
  }
}
</style>
