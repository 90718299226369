<template>
  <Dialog :open="true" @close="emits('update:open', false)">
    <DialogContainer class="inbox-dialog-container">
      <DialogHeader
        :title="$t('inbox.title')"
        icon="crossmark"
        @close="emits('update:open', false)"
      />
      <div class="inbox-scroll-container">
        <BSCLSearchInput
          v-model="searchInput"
          @update:modelValue="onSearchInput"
          :placeholder="$t('inbox.search')"
          data-qa="inbox-search"
          wrapperStyle="min-width: 100%"
          input-class="text-xl"
        />
        <div v-if="!messages?.length" class="no-results">
          <SvgIcon :size="64" name="mail" />
          <span>{{ $t(searchInput ? 'inbox.no-results' : 'inbox.no-messages-yet') }}</span>
        </div>
        <div v-else-if="groupedMessages?.length" class="w-full">
          <DayWrapper
            v-for="day of groupedMessages"
            :key="day[0]"
            :date="day[0]"
            class="w-full mb-8"
          >
            <MessageCard
              v-for="message of day[1]"
              :key="message.id"
              :message="message"
              :highlight="searchInput"
              @click="onMessageClicked(message)"
              @delete="showDeleteConfirmation(message.id)"
            />
          </DayWrapper>
        </div>
        <Loader v-if="loading" class="text-accent-500" />
      </div>
    </DialogContainer>
  </Dialog>

  <DeleteMessageConfirmationPopup
    :open="!!deleteMessageId"
    @close="deleteMessageId = null"
    @delete="onDeleteClicked"
  />

  <MessageDetailsDialog :message="selectedMessage" @close="selectedMessage = null" />
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { groupBy, toPairs } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import Dialog from '@/components/common/dialog/Dialog.vue'
import DialogContainer from '@/components/common/dialog/DialogContainer.vue'
import DialogHeader from '@/components/common/dialog/DialogHeader.vue'
import Loader from '@/components/common/loader/Loader.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import type { PatientMessage } from '@/generated/api'
import DayWrapper from '@/modules/appointments/components/DayWrapper.vue'
import { useCounterStore } from '@/modules/counter/store'
import BSCLSearchInput from '@/modules/survey/components/BSCLSearchInput.vue'

import { useInboxStore } from '../store'
import DeleteMessageConfirmationPopup from './DeleteMessageConfirmationPopup.vue'
import MessageCard from './MessageCard.vue'
import MessageDetailsDialog from './MessageDetailsDialog.vue'

defineProps<{ open: boolean; messageId?: number }>()
const emits = defineEmits<{ (e: 'update:open', value: boolean): void }>()

const store = useInboxStore()
const counterStore = useCounterStore()
const route = useRoute()

const scrollContainer = ref<HTMLElement | null>(null)
const isScrollAvailable = ref(false)

const searchInput = ref('')
const messages = ref<PatientMessage[]>([])
const loading = ref(false)
const nextPageUrl = ref<string | null | undefined>(null)
const selectedMessage = ref<PatientMessage | null>(null)
const deleteMessageId = ref<number | null>(null)

const groupedMessages = computed(() =>
  toPairs(groupBy(messages.value, ({ created_at }) => created_at.slice(0, 10)))
)

onMounted(async () => {
  scrollContainer.value = document.querySelector('.inbox-scroll-container')
  scrollContainer.value?.addEventListener('scroll', handleScroll)

  const { scrollHeight = 0, clientHeight = 0, scrollTop = 0 } = scrollContainer.value || {}
  isScrollAvailable.value = scrollHeight > clientHeight + scrollTop + 50
  if (!isScrollAvailable.value) {
    loadNextPage()
  }
  await getMessagesFirstPage()
  if (route.query.message_id) {
    const message = messages.value.find((message) => message.id === Number(route.query.message_id))
    onMessageClicked(message ?? (await store.getInboxMessage(Number(route.query.message_id))))
  }
})

const showDeleteConfirmation = async (id: number) => {
  deleteMessageId.value = id
}

const onDeleteClicked = async () => {
  if (!deleteMessageId.value) return

  await store.deleteInboxMessage(deleteMessageId.value)

  messages.value = messages.value.filter((message) => message.id !== deleteMessageId.value)
  deleteMessageId.value = null
}

const onMessageClicked = async (message: PatientMessage) => {
  selectedMessage.value = message
  const updatedMessage = await store.updateInboxMessage(message.id, { is_read: true })
  messages.value.find(({ id }) => id === message.id)!.is_read = updatedMessage.is_read

  await counterStore.getCounter()
}

const getMessagesFirstPage = async () => {
  loading.value = true

  const response = await store.getInboxMessages(searchInput.value)
  messages.value = response?.results || []
  nextPageUrl.value = response?.next

  loading.value = false
}

const onSearchInput = useDebounceFn(async () => {
  if ([1, 2].includes(searchInput.value.length)) {
    return
  }

  loading.value = true

  const response = await store.getInboxMessages(searchInput.value)
  messages.value = response?.results || []
  nextPageUrl.value = response?.next

  loading.value = false
}, 1000)

const handleScroll = async () => {
  const { scrollHeight = 0, clientHeight = 0, scrollTop = 0 } = scrollContainer.value || {}
  isScrollAvailable.value = scrollHeight > clientHeight + scrollTop + 50

  if (scrollContainer.value && nextPageUrl.value && !isScrollAvailable.value && !loading.value) {
    await loadNextPage()
  }
}

const loadNextPage = async () => {
  if (!nextPageUrl.value || loading.value) {
    return
  }
  loading.value = true
  const response = await store.getNextPage(nextPageUrl.value)
  response?.results?.forEach((message: PatientMessage) => {
    messages.value.push(message)
  })
  nextPageUrl.value = response?.next
  loading.value = false
}
</script>

<style lang="postcss" scoped>
.inbox-dialog-container {
  @apply bg-base-gradient;
}
.inbox-scroll-container {
  @apply flex flex-col h-full w-full overflow-y-auto gap-10 items-center p-5;

  &::-webkit-scrollbar {
    display: none;
  }
  .no-results {
    @apply grow flex flex-col items-center justify-center text-gray-600;
  }
}
</style>
