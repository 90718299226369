<template>
  <Teleport to="body">
    <transition name="overlay">
      <Overlay v-if="open" @click="emit('close')" />
    </transition>
    <Transitions v-if="transitionName" :name="transitionName">
      <div v-if="open" class="dialog" :class="{ fullscreen }" data-qa="dialog" v-bind="$attrs">
        <slot />
      </div>
    </Transitions>
    <transition v-else name="dialog">
      <div v-if="open" class="dialog" :class="{ fullscreen }" data-qa="dialog" v-bind="$attrs">
        <slot />
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, type PropType } from 'vue'

import Overlay from '@/components/common/overlay/Overlay.vue'

import Transitions from '../transition/Transitions.vue'

const props = defineProps({
  open: { type: Boolean },
  fullscreen: { type: Boolean, default: false },
  transitionName: {
    type: String as PropType<
      | 'dropdown'
      | 'footer'
      | 'bubble'
      | 'fade'
      | 'clear'
      | 'slide'
      | 'slide-right'
      | 'slide-left'
      | 'slide-up'
    >,
  },
})
const emit = defineEmits<{ (e: 'close'): void; (e: 'opened'): void }>()

onMounted(() => {
  window.addEventListener('keydown', keyboardHandler)
})

onUnmounted(() => {
  window.removeEventListener('keydown', keyboardHandler)
})

function keyboardHandler(event: KeyboardEvent) {
  if (props.open && event.code?.toLowerCase() === 'escape') {
    emit('close')
  }
}
</script>

<style scoped lang="postcss">
.dialog {
  @apply absolute inset-y-0 right-0 z-dialog flex flex-col;
  &.fullscreen {
    @apply left-0 w-full;
  }
}

/* Transition does not work properly if removed from here to <Transitions> component */
.dialog-enter-active,
.dialog-leave-active {
  @apply transition-[opacity,right] duration-sidebar-opening;
}

.dialog-enter-from,
.dialog-leave-to {
  @apply right-[-320px] opacity-0;
}

.overlay-enter-active,
.overlay-leave-active {
  @apply transition-opacity duration-sidebar-opening;
}

.overlay-enter-from,
.overlay-leave-to {
  @apply opacity-0;
}
</style>
