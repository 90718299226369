/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomTokenRefresh } from '../models/CustomTokenRefresh';
import type { Login } from '../models/Login';
import type { LoginResponse } from '../models/LoginResponse';
import type { LongLivedTokenRefresh } from '../models/LongLivedTokenRefresh';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @returns LoginResponse
     * @throws ApiError
     */
    public loginCreate({
        requestBody,
        isNewVersion,
    }: {
        requestBody: Login,
        /**
         * Should be true if the app is updated to the latest version
         */
        isNewVersion?: boolean,
    }): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/login/',
            query: {
                'is_new_version': isNewVersion,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @returns CustomTokenRefresh
     * @throws ApiError
     */
    public loginRefreshCreate({
        requestBody,
    }: {
        requestBody: CustomTokenRefresh,
    }): CancelablePromise<CustomTokenRefresh> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/login/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @returns LongLivedTokenRefresh
     * @throws ApiError
     */
    public loginRefreshLongLivedCreate({
        requestBody,
    }: {
        requestBody: LongLivedTokenRefresh,
    }): CancelablePromise<LongLivedTokenRefresh> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/login/refresh-long-lived/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
