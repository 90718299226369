<template>
  <div class="card bg-white" data-qa="message-card">
    <div class="container">
      <div class="text-container">
        <div class="text-container__header">
          <Status v-if="!message.is_read" color="warning">
            <span>{{ $t(message.is_updated ? 'inbox.updated' : 'inbox.new') }}</span>
          </Status>
          <Status v-if="message.is_urgent" color="danger"
            ><span>{{ $t('inbox.urgent') }}</span>
          </Status>
          <SvgIcon name="clock" :size="14" class="shrink-0" />
          <span>{{ dayjs(message.created_at).format('HH:mm') }}</span>
          <span class="divider" />
          <SvgIcon name="person" :size="14" class="shrink-0" />
          <span class="practitioner">{{ message.practitioner_name }}</span>
          <Button
            icon="trash"
            icon-only
            color="key"
            type="secondary"
            size="small"
            @click.stop="emits('delete', message.id)"
          />
        </div>
        <span class="text-container__title">
          <WordHighlighter :query="highlight" highlight-class="rounded-[4px]">
            {{ message.title }}
          </WordHighlighter>
        </span>
        <span class="text-container__body">
          <WordHighlighter :query="highlight" highlight-class="rounded-[4px]">
            {{ message.body ? message.body + '...' : '' }}
          </WordHighlighter>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import WordHighlighter from 'vue-word-highlighter'

import Button from '@/components/common/buttons/Button.vue'
import Status from '@/components/common/status/Status.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import dayjs from '@/dayjs'
import type { PatientMessage } from '@/generated/api'

defineProps<{
  message: PatientMessage
  highlight?: string
}>()

const emits = defineEmits<{
  (e: 'delete', id: number): void
}>()
</script>

<style scoped lang="postcss">
.card {
  @apply relative overflow-hidden shadow-card bg-white rounded-md flex items-center  text-gray-600 cursor-pointer w-full mb-0;
  z-index: 10;
  .container {
    @apply flex w-full h-full flex-col;
  }
  .text-container {
    @apply flex flex-col p-3 max-h-full;
    &__header {
      @apply text-base text-gray-600 flex flex-row items-center gap-1;
      font-feature-settings: 'liga' off, 'clig' off;

      .practitioner {
        @apply grow truncate;
      }

      .divider {
        @apply border-r border-gray-300 h-[14px] w-0;
      }
    }
    &__title {
      @apply text-lg font-bold text-gray-800 truncate;
    }
    &__body {
      @apply text-base text-gray-800 truncate;
    }
  }
}
</style>
