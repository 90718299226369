<template>
  <InputWrapper
    ref="wrapper"
    class="input-wrapper"
    :class="{ focused: focused }"
    :style="wrapperStyle"
    :data-qa="dataQa ? dataQa + '-input' : null"
    :disabled="disabled"
    :error="error"
    :focused="focused || focusedEnforce"
    no-borders
  >
    <SvgIcon
      :size="searchIconSize"
      name="search"
      class="search-icon text-gray-400"
      :class="focused && 'focused'"
    ></SvgIcon>
    <input
      :id="inputId"
      ref="input"
      :class="classNames"
      :disabled="disabled"
      :value="modelValue"
      :readonly="readonly"
      :placeholder="showPlaceholder"
      :data-qa="dataQa ? dataQa : null"
      v-bind="attrs"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <Transitions name="clear">
      <SvgIcon
        v-if="modelValue"
        :size="24"
        name="circle-crossmark"
        class="clear-button text-gray-400 grow-0"
        @click="$emit('update:modelValue', '')"
      />
    </Transitions>
  </InputWrapper>
</template>

<script setup lang="ts">
import { computed, onMounted, type PropType, ref, useAttrs, useSlots } from 'vue'

import {
  useInputWrapped,
  useInputWrappedEmits,
  useInputWrappedProps,
} from '@/components/common/form/composables'
import InputWrapper from '@/components/common/form/InputWrapper.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import Transitions from '@/components/common/transition/Transitions.vue'

const attrs = useAttrs()
const slots = useSlots() as { before: any; after: any }

const props = defineProps({
  modelValue: { type: String },
  dataQa: { type: String, default: '' },
  mask: { type: [String, Function] as PropType<string | number | Date> },
  inputId: { type: String },
  transform: { type: Array as PropType<((v: string) => string)[]>, default: () => [] },
  autofocus: { type: Boolean, default: false },
  focusedEnforce: { type: Boolean, default: false },
  noBorders: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  wrapperStyle: { type: String, default: '' },
  inputClass: { type: String, default: '' },
  searchIconSize: { type: Number, default: 40 },
  ...useInputWrappedProps(),
})

const emits = defineEmits({
  ...useInputWrappedEmits(),
})

const { disabled, error, focused, showPlaceholder, onFocus, onBlur, inputHandler } =
  useInputWrapped(props, emits)

const input = ref<HTMLInputElement>()
const wrapper = ref<typeof InputWrapper>()

const onInput = (event: Event): void => {
  inputHandler(event, input.value as HTMLInputElement, props.transform)
}

onMounted(() => {
  if (props.autofocus) {
    input.value?.focus()
  }
})

const classNames = computed(() => ({
  'input-text': true,
  'with-before': slots.before,
  'with-after': slots.after,
  [props.inputClass]: true,
}))

const focus = () => {
  if (input.value) {
    input.value.focus()
  }
}

defineExpose({
  focus,
})
</script>

<style lang="postcss" scoped>
.input-text {
  @apply w-full outline-none bg-transparent text-gray-800;

  /* autofill breaks radius https://stackoverflow.com/a/67100504/2848264 */
  transition: background-color 2147483647s;
}

.input-wrapper {
  @apply py-1 transition-colors font-bold text-2xl bg-none rounded-none border-b border-gray-400 min-w-[350px] max-w-[350px] md:max-w-[480px] md:min-w-[480px];
  .search-icon {
    @apply mr-1;
    &.focused {
      @apply text-accent-500;
    }
  }
  .clear-button {
    @apply text-gray-600;
  }

  &.focused {
    @apply border-accent-500 text-accent-500;
    .clear-button {
      @apply text-gray-600;
    }
  }
}
</style>
