<template>
  <Sidebar :open="open" @overlay-click="closeMenu">
    <template #header>
      <div class="app-sidebar__header">
        <Button
          size="large"
          type="secondary"
          icon="crossmark"
          icon-only
          @click="closeMenu"
          data-qa="close-menu-button"
        />
        <Badge :label="messagesCounter" v-if="isPatient">
          <Button
            size="large"
            type="secondary"
            icon="mail"
            icon-only
            @click="onMessagesClicked"
            data-qa="messages-button"
          />
        </Badge>
      </div>
      <img src="@/assets/myclienia-logo.png" alt="Logo" :height="56" :width="150" class="mt-10" />
    </template>
    <div class="flex flex-col flex-grow justify-center items-center gap-1">
      <RouterLink
        v-if="isPatient"
        :to="{ name: HOME }"
        class="menu-card"
        data-qa="home-link"
        @click="closeMenu"
      >
        <SvgIcon name="home" :size="24" />
        <span class="flex-grow mx-5">{{ t('sidebar.home') }}</span>
      </RouterLink>
      <RouterLink
        v-if="isPatient"
        :to="{ name: APPOINTMENTS }"
        class="menu-card"
        data-qa="appointments-link"
        @click="closeMenu"
      >
        <SvgIcon name="calendar" :size="24" />
        <span class="flex-grow mx-5">{{ t('sidebar.appointments') }}</span>
        <Badge :label="appointmentsCounter" visible inline />
      </RouterLink>
      <RouterLink
        :to="{ name: SURVEYS }"
        class="menu-card"
        data-qa="survey-link"
        @click="closeMenu"
      >
        <SvgIcon name="questionnaire" :size="24" />
        <span class="flex-grow mx-5">{{ t('sidebar.questionnaires') }}</span>
        <Badge :label="surveysCounter" visible inline />
      </RouterLink>
      <RouterLink
        v-if="isPatient && newsStore.hasNews"
        :to="{ name: NEWS }"
        class="menu-card"
        data-qa="info-link"
        @click="closeMenu"
      >
        <SvgIcon name="news" :size="24" />
        <span class="flex-grow mx-5">{{ t('sidebar.news') }}</span>
        <Badge :label="newsCounter" visible inline />
      </RouterLink>
      <RouterLink
        v-if="isPatient && infoStore.hasInfoArticles"
        :to="{ name: INFO }"
        class="menu-card"
        data-qa="info-link"
        @click="closeMenu"
      >
        <SvgIcon name="circle-question" :size="24" />
        <span class="flex-grow mx-5">{{ t('sidebar.info') }}</span>
      </RouterLink>
      <RouterLink
        v-if="isPractitioner"
        :to="{ name: MESSAGING }"
        class="menu-card"
        data-qa="info-link"
        @click="closeMenu"
      >
        <SvgIcon name="messaging" :size="24" />
        <span class="flex-grow mx-5">{{ t('sidebar.messaging') }}</span>
      </RouterLink>
    </div>
    <div class="flex flex-col pb-16 pt-8 border-t border-gray-200">
      <RouterLink
        :to="{ name: SETTINGS }"
        class="system-menu-item"
        @click="closeMenu"
        data-qa="settings-link"
      >
        <SvgIcon name="cog" :size="16" />
        {{ t('sidebar.settings') }}
      </RouterLink>
      <RouterLink
        :to="{ name: PROFILE }"
        class="system-menu-item"
        @click="closeMenu"
        data-qa="profile-link"
      >
        <SvgIcon name="user" :size="16" />
        {{ t('sidebar.my-profile') }}
      </RouterLink>
      <RouterLink
        :to="{ name: HISTORY }"
        class="system-menu-item"
        @click="closeMenu"
        data-qa="history"
      >
        <SvgIcon name="timeline" :size="16" />
        {{ t('sidebar.history') }}
      </RouterLink>
      <RouterLink :to="{ name: LOGIN }" class="system-menu-item" @click="logout" data-qa="logout">
        <SvgIcon name="log-out" :size="16" />
        {{ t('sidebar.logout') }}
      </RouterLink>
    </div>
    <template #footer>
      <p class="text-base text-gray-700">{{ t('common.clienia-ag') }}</p>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import Badge from '@/components/common/badge/Badge.vue'
import Button from '@/components/common/buttons/Button.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import { useUserRoles } from '@/composables/useUserRoles'
import { APPOINTMENTS } from '@/modules/appointments/routes'
import { LOGIN } from '@/modules/auth/routes'
import { useAuthStore } from '@/modules/auth/store'
import { useCounterStore } from '@/modules/counter/store'
import { HISTORY } from '@/modules/history/routes'
import { HOME } from '@/modules/home/routes'
import { INFO } from '@/modules/info/routes'
import { useInfoStore } from '@/modules/info/store'
import { PROFILE } from '@/modules/my-profile/routes'
import { NEWS } from '@/modules/news/routes'
import { useNewsStore } from '@/modules/news/store'
import { MESSAGING } from '@/modules/patient-messaging/routes'
import { SETTINGS } from '@/modules/settings/routes'
import { SURVEYS } from '@/modules/survey/routes'

import Sidebar from '../common/sidebar/Sidebar.vue'

defineProps<{
  open: boolean
}>()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'open-inbox'): void
}>()

const { t } = useI18n()
const store = useAuthStore()
const counterStore = useCounterStore()
const newsStore = useNewsStore()
const infoStore = useInfoStore()
const { isPatient, isPractitioner } = useUserRoles()
const router = useRouter()

const appointmentsCounter = computed(() => counterStore.counter?.new_appointments || 0)
const newsCounter = computed(() => counterStore.counter?.news || 0)
const surveysCounter = computed(() => counterStore.counter?.surveys || 0)
const messagesCounter = computed(() => counterStore.counter?.messages || 0)

const closeMenu = () => {
  emit('update:open', false)
}

const logout = async () => {
  if (Capacitor.isNativePlatform()) {
    await store.removeFcmToken()
    localStorage.removeItem('fcmToken')
    await PushNotifications.unregister()
  }
  await store.logout(true)
  router.push({ name: LOGIN })
}

const onMessagesClicked = () => {
  closeMenu()
  emit('open-inbox')
}
</script>

<style scoped lang="postcss">
.app-sidebar__header {
  @apply flex justify-between items-center safe-top;
}
.menu-card {
  @apply flex text-key-500 p-5 bg-white shadow-card rounded-md w-full text-lg font-bold items-center;

  &.router-link-active {
    @apply bg-accent-500 text-white;
  }

  &:not(.router-link-active):hover {
    @apply shadow-card-hover text-key-700;
  }
}

.system-menu-item {
  @apply mb-5 flex items-start text-base font-bold text-key-600 hover:text-key-800;

  .svg-icon {
    @apply flex-shrink-0 mr-1 mt-[2px];
  }

  &.router-link-active {
    @apply text-gray-800;
  }
}
</style>
