<template>
  <div class="flex flex-col w-full" data-qa="day">
    <div class="header">
      <div class="day">{{ day }}</div>
      <div class="mt-[2px]">
        {{ month }}, <span class="text-gray-500">{{ weekDay }}</span>
      </div>
    </div>
    <div class="flex flex-col w-full gap-0.5">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import dayjs from '@/dayjs'

const props = defineProps<{ date: string }>()

const { t } = useI18n()
const day = computed(() => dayjs(props.date).format('D'))
const month = computed(() => dayjs(props.date).format('MMMM'))
const weekDay = computed(() => {
  let additionalInfo = ''
  if (dayjs(props.date).isToday()) {
    additionalInfo = `(${t('appointments.today')})`
  } else if (dayjs(props.date).isTomorrow()) {
    additionalInfo = `(${t('appointments.tomorrow')})`
  }
  return `${dayjs(props.date).format('dddd')} ${additionalInfo}`
})
</script>

<style scoped lang="postcss">
.header {
  @apply flex pb-3 px-4 text-lg font-bold items-start;

  .day {
    border-radius: 4px;
    border: 1.5px solid rgba(34, 38, 46, 0.2);
    @apply w-[28px] h-[28px] text-center relative flex-shrink-0 pt-[1px] mb-1 mr-[6px];

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 25px;
      height: 1.5px;
      background: rgba(34, 38, 46, 0.2);
    }
  }
}
</style>
