/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Message } from '../models/Message';
import type { PaginatedMessageList } from '../models/PaginatedMessageList';
import type { PaginatedRecentMessagePatientsList } from '../models/PaginatedRecentMessagePatientsList';
import type { PatchedMessage } from '../models/PatchedMessage';
import type { SendMessage } from '../models/SendMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PractitionersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedMessageList
     * @throws ApiError
     */
    public practitionersMessagesList({
        group,
        limit,
        offset,
        ordering,
        patient,
        search,
    }: {
        /**
         * Group by patient
         */
        group?: boolean,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        patient?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedMessageList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/practitioners/messages/',
            query: {
                'group': group,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'patient': patient,
                'search': search,
            },
        });
    }

    /**
     * @returns Message
     * @throws ApiError
     */
    public practitionersMessagesRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Message> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/practitioners/messages/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Message
     * @throws ApiError
     */
    public practitionersMessagesUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Message,
    }): CancelablePromise<Message> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/practitioners/messages/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Message
     * @throws ApiError
     */
    public practitionersMessagesPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedMessage,
    }): CancelablePromise<Message> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/practitioners/messages/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public practitionersMessagesDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/practitioners/messages/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Resend notification for a specific message to the patient
     * @returns any Notification resent successfully
     * @throws ApiError
     */
    public resendMessageNotification({
        id,
    }: {
        /**
         * Message ID
         */
        id: number,
    }): CancelablePromise<{
        status?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/practitioners/messages/{id}/resend-notification/',
            path: {
                'id': id,
            },
            errors: {
                403: `Not a practitioner`,
                404: `Message not found`,
            },
        });
    }

    /**
     * @returns PaginatedRecentMessagePatientsList
     * @throws ApiError
     */
    public practitionersMessagesRecentPatientsList({
        limit,
        offset,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
    }): CancelablePromise<PaginatedRecentMessagePatientsList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/practitioners/messages/recent-patients/',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @returns SendMessage
     * @throws ApiError
     */
    public practitionersMessagesSendCreate({
        requestBody,
    }: {
        requestBody: SendMessage,
    }): CancelablePromise<SendMessage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/practitioners/messages/send/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
